/* Add these new styles */
body.sidebar-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}
.bottomHeader 
{
width:100%;
display: flex;
padding: 0px 240px;
align-items: flex-end;
gap: 2px;
flex: 1 0 0;
align-self: stretch;
}
.logoFrame
{
    display: flex;
width: 200px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
}
.navFrame
{
display: flex;
justify-content: flex-end;
align-items: flex-start;
gap: 2px;
flex: 1 0 0;
position: relative;
}
.navMenu {
  display: flex;
  padding: 22px 48px;
  gap: 12px;
  align-items: center;
  background: #F8F9FA;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  border-bottom: 6px solid transparent;
}

.navMenu:hover {
  background: #BE1E2D;
}

.navMenu:hover .menuText {
  color: #FFFFFF;
}

.navMenu.active {
  background: #F8F9FA;
  border-bottom: 6px solid #BE1E2D;
}

.navMenu.active .menuText {
  color: #BE1E2D;
}

.dropdownArrow {
  transition: filter 0.3s ease, transform 0.3s ease; /* Separate transitions */
}

.navMenu:hover .dropdownArrow,
.dropdown-container:hover .dropdownArrow {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%); /* This makes it #FFFFFF (white) */
  transform: rotate(-180deg);
}

.navMenu.active .dropdownArrow {
  filter: invert(15%) sepia(95%) saturate(2713%) hue-rotate(343deg) brightness(87%) contrast(90%); /* This makes it #BE1E2D */
}

.menuText {
  color: #202124;
  font-family: "Segoe UI";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  transition: color 0.3s ease-in-out;
}
.fa-icon{
    color: #202124;
}
.navMenu:hover {
    background: #BE1E2D;
  }
.navMenu:hover .menuText {
    color: #F8F9FA;
    transition: color 0.3s ease-in-out;
  }


  
  .fa-chevron-down {
    display: flex;
  }
  .fa-chevron-up{
    display: none;
  }
  .navMenu:hover .fa-chevron-up {
    display: block;
    color: #F8F9FA;
    transition: color 0.3s ease-in-out;
  }

  .mobileNavSideBar {
    display:None;
}
  
  .menuLayer {
    display: none;
  }
 .mobileSidebarTop{
  display: flex;
  width:100%;
  padding: 16px;
  justify-content: space-between;
align-items: center;
 }
.mobileNavItemBox
 {
display: flex;
width: 100%;
flex-direction: column;
align-items: flex-start;
flex-shrink: 0;
 }
 .mobileNavItem
 {
display: flex;
height: 56px;
width: 100%;
padding: 16px 16px;
align-items: center;
justify-content: space-between;
/* gap: 16px; */
align-self: stretch;
border-bottom: 1px solid rgba(0, 0, 0, 0.50);
 }

 .mobileNavItemDropdown
 {
display: flex;
height: full;
width: 100%;
padding: 4px 16px 4px 72px;
align-items: center;
/* gap: 16px; */
align-self: stretch;
border-bottom: 1px solid rgba(0, 0, 0, 0.50);
 }

.mobileNavItemText
 {
  color: #FFF;
/* M3/body/large */
font-size: var(--Body-Large-Size, 17px);
font-style: normal;
font-weight: 400;
/* line-height: var(--Body-Large-Line-Height, 24px);  */
letter-spacing: var(--Body-Large-Tracking, 0.5px);
align-self: stretch;
 }

.mobileNavBottom
 {
  display: flex;
padding: 24px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 12px;
align-self: stretch;
 }
 .mobileNavBottomText
 {
  color: #FFF;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
align-self: stretch;
 }
 .mobileNavBottomIconBox
 {
  display: flex;
padding: 6px 0px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: var(--Medium, 4px);
background: var(--Neutral-Background-Subtle-Rest, rgba(255, 255, 255, 0.00));
 }
 .mobileNavBottomJoin{
  color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 114.286% */
 }
 .active-mobile-nav-item {
  background: rgba(0, 0, 0, 0.10);
}
/* Wrapper to enable dropdown positioning */
/* .navMenu-wrapper {
  position: relative;
} */

/* Dropdown Menu Styling */
.dropdownMenu {
  display: none;
  position: absolute;
  top: 100%; /* Position directly below the "Sister Concerns" link */
  left: 0;
  padding: 24px;
  z-index: 10;
  min-width: 100%;
background: #FFF;
border-radius: 0 0 5px 5px;
}

.dropdownMainBox
{
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates 5 equal columns */
  gap: 24px;
}

.dropdownIconBox
{
display: flex;
padding: 6px;
justify-content: center;
align-items: center;
gap: 24px;
border-radius: 15px;
background: #F8F9FA;
transition: transform 0.3s ease-out;
align-self: stretch;
}

.dropdownIconBox:hover{
  transform: scale(0.95); 
}
/* .dropdownLogo
{
  width: 167px;
height: 54px;
} */

/* Show dropdown on hover over the entire navMenu-wrapper */
.navMenu-wrapper:hover .dropdownMenu {
  display: block;
}

.sidemobileDropdownArrow {
  transition: transform 0.3s ease;
}

.rotate-arrow {
  transform: rotate(180deg);
}

  @media (max-width: 768px) {
    .mobileNavSideBar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 80%;
      height: 125vh;
      background: #BE1E2D;
      position: fixed;
      top: 0;
      left: 0;  /* Changed from right to left */
      z-index: 1000;
      overflow-y: auto;
      overscroll-behavior: contain; /* Prevents scroll chaining */
      -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }
    .bottomHeader 
    {
display: flex;
padding: 0px 16px;
justify-content: space-between;
align-items: center;
align-self: stretch;
    }
    .logoFrame
{
  display: flex;
  padding: 6px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.navFrame
{
display: none;
}
.menuLayer
{
display: flex;
padding: 8px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: var(--Spacing---1, 100px);
background: var(--State-Layers-On-Surface-Variant-Opacity-08, rgba(73, 69, 79, 0.08));
}
  }
