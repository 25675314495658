.anatomyMain
{
    display: flex;
    min-height: 400px;
    padding: 150px 240px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background: #FFF;
    align-self: stretch;
}
.anatomySmallCard
{
display: flex;
justify-content: center;
align-items: center;
gap: 24px;
border-radius: 15px;
background: #F1F1F1;
overflow: hidden; 
align-self: stretch;
}

.shapeAnatomyBg {
display: flex;
width: 200px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 36px;
align-self: stretch;
background-color: #3C4043;
background-repeat: no-repeat;
background-size: cover;
background-image: url('../../Assets/anatomyshape.png');
background-position: center;
position: relative;
border-top-left-radius: 15px;
border-bottom-left-radius: 15px;
  }
  
  .shapeAnatomyBg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(60, 64, 67, 0.9); /* #222 with 20% transparency */
    z-index: 1;
    pointer-events: none; /* Allow interaction with content */
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .shapeAnatomyBg > * {
    position: relative;
    z-index: 2;
}

  .shapeAnatomyLetter
  {
    color: #FFF;
text-align: center;
font-size: 55px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 109.091% */
  }
.anatomyDescriptionBox
  {
display: flex;
padding: 36px 24px 36px 0px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
flex: 1 0 0;
  }
.anatomyTitle
  {
color: #5F6368;
font-size: 55px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 109.091% */
align-self: stretch;
  }
.anatomyDescriptionText
  {
    color: #202124;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
align-self: stretch;
  }
/* top head  */
  .anatomyHeadBox
  {
    display: flex;
height: 400px;
justify-content: center;
align-items: center;
gap: 24px;
border-radius: 15px;
background: #222;
overflow: hidden;
align-self: stretch;
  }

.anatomyLeftBox
  {
display: flex;
width: 960px;
padding: 36px;
flex-direction: column;
align-items: flex-start;
gap: 36px;
  }
.anatomyLeftTitleBox
  {
    display: flex;
align-items: center;
gap: 24px;
align-self: stretch;
  }
.anatomyLeftTitle
  {
    color: #FFF;
font-size: 55px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 109.091% */
  }
.anatomyLeftBonding
  {
    display: flex;
padding-left: 110px;
align-items: flex-start;
gap: 24px;
  }

.anatomyRightBonding
  {
    display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 36px;
/* flex: 1 0 0; */
align-self: stretch;
  }
  .anatomyRightBondingImg
  {
    
/* max-width: 497px; */
  width: 100%;
  height: auto;
  object-fit: cover;
border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }


  /* mobile responsiveness  */
  @media (max-width: 768px) {
    .anatomyMain
    {
      display: flex;
      min-height: auto;
      padding: 16px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      position: relative;
    }
  
    .anatomyHeadBox
    {
        min-height: auto; /* Changed from fixed height */
        height: auto;
  flex-direction: column-reverse;
  position: relative; /* Add this */
  width: 100%;
    }
  
    .anatomySmallCard
  {
  min-height: 220px;
  flex-direction: column;
  gap: 0px;
  min-height: auto; /* Changed from fixed height */
  height: auto;
  width: 100%; /* Add this */
  position: relative; 
  }
  .shapeAnatomyBg
  {
    display: flex;
flex-direction: column;
width: 100%;
padding: 24px 0;
justify-content: center;
align-items: center;
gap: 0px;
flex: 1 0 0;
border-bottom-left-radius: 0px;
align-self: stretch;
  }

  .anatomyDescriptionBox
  {
padding: 24px;
  }
  .anatomyLeftBox
  {
width:100%;
  }
  .anatomyLeftTitleBox
  {
padding: 36px;
flex-direction: column;
align-items: flex-start;
gap: 36px;
  }
  .anatomyLeftTitleBox {
    align-items: center;
    gap: 24px;
}

.anatomyLeftTitle {
    color: #FFF;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 200% */
}
.anatomyLeftBonding {
    
    padding-left: 0px;
    
}
.anatomyRightBonding {
    display: flex;
    width: 100%;
    height: 398px; /* Fixed height container */
    justify-content: center;
    align-items: center;
    overflow: hidden; /* This prevents image overflow */
    border-top-left-radius: 15px;  /* Added */
    border-top-right-radius: 15px;
  }

  .anatomyRightBondingImg {
    width: 100%;
    height: 398px;
    object-fit: cover; /* This ensures image covers the area without distortion */
    border-radius: 0; /* Remove border radius for mobile if needed */
  }
    }