.header_main_top {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 400px;
  align-items: center;
  background-color: #000;
  /* background-image: url('../../Assets/location.png'); */
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Overlay */
.header_main_top::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 34, 34, 0.7);
  z-index: 1;
  pointer-events: none;
}

/* Left white box */
.leftWhite {
  display: flex;
  width: 400px;
  height: 300px;
  position: absolute;
  top: 0;
  left: -28px;
  z-index: 2;
}

/* Right white box */
.rightWhite {
  display: flex;
  width: 400px;
  height: 300px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
}

/* Text Box */
.text_box_header {
  display: flex;
  padding-left: 240px;
  padding-right: 1100px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  z-index: 2;
}

.text_shape_header {
  display: flex;
  padding: 24px 24px 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
}

.text_header_ {
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px; /* 133.333% */
}

/* Responsive styles for screens smaller than 768px */
@media (max-width: 768px) {
  .header_main_top {
    height: 180px; /* Shrink height */
    background-size: cover;  /* Ensure it covers the container */
    background-repeat: no-repeat; /* Shrink background to fit div */
  }

  /* Hide the white boxes */
  .leftWhite, .rightWhite {
    display: none;
  }

  /* Center text */
  .text_box_header {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    justify-content: center; /* Vertically center text */
    align-items: center; /* Horizontally center text */
  }

  .text_header_ {
    font-size: 40px; /* Adjust font size for smaller screens */
    line-height: 50px; /* Adjust line-height for smaller screens */
  }
}
