@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

body {
  position: relative;
}

/* #root Specific Styles */
#root {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: 100vh;
}

.scaled-content {
  transform: scale(0.8);
  transform-origin: top left;
  width: 125%;
  min-height: 125vh;
  overflow-y: auto;
}

.main-wrapper {
  padding-top: 105px; /* Adjust this value based on your navbar height */
}

/* Add these new styles */
body.sidebar-open {
  overflow: hidden;
}

.mobileNavSideBar {
  transform: scale(1.25); /* Counter the root scale */
  transform-origin: top left;
  width: 80vw; /* Compensate for the scale (100/1.25) */
  height: 80vh; /* Compensate for the scale */
}


@media (max-width: 768px) {
  .mobileNavSideBar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  
  /* Counter the root scale for sidebar content */
  .mobileSidebarTop,
  .mobileNavItemBox,
  .mobileNavBottom {
    transform-origin: top left;
  }
}

/* Keep existing styles */
main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
  width: 100%;
}